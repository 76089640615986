import React, { Suspense, lazy, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./index.css";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/ErrorBoundary";
import logo from "./components/navbar/logo.webp";
import Axios from "axios";

const App = lazy(() => import("./App"));
const Apply = lazy(() => import("./apply/apply"));
const Contact = lazy(() => import("./components/contact/contact"));
const Leadership = lazy(() => import("./components/leadership/leadership"));
const ApplyForm = lazy(() => import("./apply/apply-form/ApplyForm"));
const NotFound = lazy(() => import("./components/notFound/NotFound"));
const PaymentPdf = lazy(() => import("./apply/pdf/PaymentPdf"));

export default function ScrollToTop() {
  // Resets scroll when changing pages
  const { pathname } = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [pathname]);

  return null;
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <ScrollToTop />

    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
      <Suspense
        fallback={
          <div
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={logo} alt="Iri Logo" width="200px" height="auto" />
            <h2>loading...</h2>
          </div>
        }
      >
        {" "}
        <Routes>
          <Route path="/" element={<App />} />

          <Route path="/contact" element={<Contact />} />

          <Route path="/apply" element={<Apply />} />

          <Route path="/leadership" element={<Leadership />} />
          {/* 
          <Route path="/apply/form" element={<ApplyForm />} />
          <Route path="/apply/paymentPdf" element={<PaymentPdf />} /> */}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  </Router>
);
