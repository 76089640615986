import React from "react";
import logo from "./simple-logo.webp";
import Navbar from "./navbar/navbar";

function ErrorFallback({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert" style={{backgroundColor:'red', height:'100vh'}}>
      <Navbar />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{ fontSize: "2.5em", fontWeight: "bold", marginTop: "25vh" }}
        >
          Oops! Something went wrong.
        </h1>
        <h2 style={{ fontSize: "1.5em", textAlign: "center", width: "90%" }}>
          Refresh the page or nagivate to another one.
        </h2>
        <img
          src={logo}
          alt="IRI logo"
          width="70rem"
          height="auto"
          style={{ marginTop: "10vh" }}
        />
      </div>
    </div>
  );
}
export default ErrorFallback;