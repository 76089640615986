import React from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import '../../generalStyle.css'
import logo from "./logo.webp";
import { useState, useEffect, useRef } from "react";
import simpleLogo from "./simple-logo.webp";

function Navbar() {
  const [state, setState] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setState(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  if (!state) {
    document.body.style.overflowY = "hidden";
  }

  return (
    <div className={`navbar`}>
      <Link to="/" className="logo">
        <img src={logo} alt="Logo" className="logo" />
      </Link>
      <Link>
        <img src={simpleLogo} alt="Logo" className="logo-simple" />
      </Link>
      <a
        href="https://www.instagram.com/irinitiative_/?hl=en"
        className="social-svg ssvg1"
        rel="noopener noreferrer"
        target="_blank"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-instagram"
        >
          <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
          <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
          <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
        </svg>
      </a>
      <a
        href="https://twitter.com/iri_program?lang=en"
        className="social-svg ssvg1"
        rel="noopener noreferrer"
        target="_blank"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-twitter"
        >
          <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
        </svg>
      </a>
      <div className="nav-items">
        <Link className="nav-item link-raw" to="/">
          About
        </Link>
        <Link to="/apply" className="nav-item link-raw">
          Apply
        </Link>
        <Link className="nav-item link-raw" to="/leadership">
          Leadership
        </Link>
        <Link to="/contact" className="nav-item link-raw">
          Contact
        </Link>
      
      </div>
      <div ref={menuRef}>
        <button className="hamburger" onClick={() => setState(!state)}>
          <div className={`line ${state ? "x-line" : "h-line"}`}></div>
        </button>
        <div className={`navbar-links ${state ? "openNav" : "closeNav"}`}>
          <Link className="nav-item hamburger-link link-raw" to="/">
            About
          </Link>
          <Link to="/apply" className="nav-item hamburger-link link-raw">
            Apply
          </Link>
          <Link className="nav-item hamburger-link link-raw" to="/leadership">
            Leadership
          </Link>
          <Link to="/contact" className="nav-item hamburger-link link-raw">
            Contact
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
